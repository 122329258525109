import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Column = ({ modifiers, utility, className, children, ...otherProps }) => {
	const baseClass = 'column';
	const defaultClass = 'col-full';

	const rootClass = cx(baseClass, className, defaultClass, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	return (
		<div className={rootClass} {...otherProps} data-animation-page>
			{children}
		</div>
	);
};

Column.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node,
};

export default Column;
