import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Video from '@components/atoms/Video';
// import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
// import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Column from '@components/organisms/Column';
import Gallery from '@components/organisms/Gallery';
// import Cta from '@components/molecules/Cta';
// import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const pageClass = 'page-project';

const accent_color = '#FFF';
const footerRelatedLinks = [
	{
		title: 'Avrion Therapeutics',
		url: '/fr/projets/avrion-therapeutics-branding/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectMjfPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/mjf-swipe/mjf_swipe_hero.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 2560, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/mjf-swipe/mjf_swipe_hero_mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 2560, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjfswipe_3: file(
					relativePath: { eq: "projects/mjf-swipe/70K.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				mjfswipe_4: file(
					relativePath: { eq: "projects/mjf-swipe/100K.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 660, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/en/projects/montreux-jazz-festival-swipe-app/',
					lang: 'en',
				},
			]}
			title="Montreux Jazz Festival Swipe App - Par Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Projets',
					link: '/fr/projets/',
				}}
				title="Montreux Jazz Festival – Swipe App"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							Générez votre programme de festival selon vos goûts,
							en quelques coups de swipe.
						</p>

						<p className="p-small">
							Reconnu pour présenter les plus grands artistes de
							tous les temps, le Montreux Jazz Festival est un
							événement unique qui célèbre la musique live depuis
							1967 sur les rives du lac Léman.
						</p>

						<div className="list-two-columns">
							<ListText
								className="element-spacing"
								title="Nos services"
								list={[
									'Workshops de stratégie',
									'Architecture de l’information',
									'Data design',
									'Direction artistique',
									'Développement web',
									'Site d’expérience',
									'Analytics',
								]}
							/>
							<ListText
								title="Awards"
								list={[
									'Site of the Day - Awwwards',
									'Developer of the Day - Awwwards',
									'Site of the Day - CSSDA',
								]}
							/>
						</div>
					</div>
					<div className="project-description text-content">
						<h3>Le challenge</h3>
						<p>
							Avec un programme dense, un nombre croissant de
							concerts, il devient difficile pour le public
							d’identifier aisément les artistes qui peuvent
							correspondre à leurs préférences musicales.
						</p>
						<h3>Notre solution</h3>
						<p>
							Nous avons créé une application Web ludique qui
							permet aux utilisateurs de découvrir le programme de
							manière plus personnalisée et accessible. En
							conséquence, près de 100 000 programmes
							personnalisés ont été générés en quelques semaines
							seulement, avec un taux de complétion de
							l'expérience de plus de 70%. L'expérience a poussé
							les festivaliers à découvrir de nouveaux artistes
							qu'ils ne connaissaient pas.
						</p>
						{/*
						<div className="project-links text-content">
							<ButtonExternal
								href="https://www.mjfswipe.com/swipeit/"
								text="Découvrir la web-app"
							/>
						</div>*/}
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<Image
								{...data.mjfswipe_4.childImageSharp}
								alt="Logo"
							/>
						</div>
						<Image
							{...data.mjfswipe_3.childImageSharp}
							alt="Logo"
						/>
					</div>
				</div>

				{/***** 1 COLUMN VIDEO *****/}
				<Video
					className="col-full"
					fluid={[
						{
							src: '6e43c600ffe904defae623b1b9850a69',
						},
						{
							src: '6005d1515a9b2d2653e6c85510dc5af5',
							minWidth: 768,
						},
					]}
					alt="Swipe App – Visualisation mobile"
				/>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-top justify-normal">
						<div className="text-content">
							<h3>
								Une expérience immersive complète, à la fois
								performante et ludique.
							</h3>
						</div>
						<div className="text-content">
							<p>
								En quelques questions, générez un programme
								unique qui correspond à vos goûts. Ainsi, vous
								pourrez découvrir les meilleurs concerts pour
								vous.
							</p>
						</div>
					</div>
				</div>

				{/***** 1 COLUMN VIDEO *****/}
				<Video
					className="col-full"
					fluid={[
						{
							/*Mobile*/
							src: 'd815bc9e7404669bc317db91e9e2e507',
						},
						{
							/*Desktop*/
							src: '510fbe13fb86e6812e23c8709af45298',
							minWidth: 768,
						},
					]}
					alt="Animation sur desktop"
				/>

				{/* 12 col + [Image + Image + Image + Image] + gutter */}
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Video
						src="a8946adb20330f380d040601e0612f89"
						alt="Version mobile – Carrée"
					/>
					<Video
						src="3b0e220bf5ef907247626763aac780c2"
						alt="Version mobile – Carrée"
					/>
				</Gallery>

				<Blockquote
					uptitle="Témoignage client"
					quote="« Superhuit a été un véritable partenaire stratégique pour le développement d'une nouvelle stratégie digitale pour le Montreux Jazz Festival et son univers commercial.  »"
					cite="Michelle Goff – Chief Digital Officer, Montreux Media Venture"
				/>

				<Column modifiers="left">
					<div className="column__media text-content">
						{/***** VIDEO RESPONSIVE *****/}
						<Video
							fluid={[
								{
									/*Mobile*/
									src: '0496d5a7ca09aec9c70ea6b07501afa3',
								},
								{
									/*Desktop*/
									src: '3d021f513faf13e369870c1f078af579',
									minWidth: 768,
								},
							]}
							alt="Affichage du programme"
						/>
					</div>

					<div className="column__text column__sticky text-content">
						<h3>
							Découvrez votre programme personnalisé avec les
							meilleurs artistes correspondants à vos goûts
						</h3>
						<p>
							Pour vous préparer à vivre l'événement comme le
							meilleur des festivaliers et à découvrir de nouveaux
							artistes selon vos préférences musicales, une
							playlist Spotify personnalisée est générée.
						</p>
					</div>
				</Column>

				{/* <Cta
					subscribe
					text="Be the first to know when we release a new project"
					cta="Subscribe"
					currentPage="Project"
				/> */}
			</section>
		</Layout>
	);
};

export default ProjectMjfPage;
