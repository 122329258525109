import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Blockquote = ({
	modifiers,
	className,
	uptitle,
	quote,
	more,
	cite,
	...otherProps
}) => {
	const baseClass = 'blockquote';
	const defaultClass = 'box';

	const rootClass = cx(baseClass, className, defaultClass, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	return (
		<div className={rootClass} {...otherProps} data-animation-page>
			<blockquote>
				<span>{uptitle}</span>
				{modifiers && modifiers.includes('big') && more ? (
					<div className="list-two-columns">
						<p className="blockquote__quote">{quote}</p>
						<div>
							<p className="blockquote__more">{more}</p>
							<cite>{cite}</cite>
						</div>
					</div>
				) : (
					<>
						<p className="blockquote__quote">{quote}</p>
						<cite>{cite}</cite>
					</>
				)}
			</blockquote>
		</div>
	);
};

Blockquote.propTypes = {
	modifiers: PropTypes.string,
	uptitle: PropTypes.string.isRequired,
	quote: PropTypes.string.isRequired,
	more: PropTypes.string,
	cite: PropTypes.string.isRequired,
};

export default Blockquote;
